import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`PSI High School Graduation and IB Diploma Programme`}</p>
    </blockquote>
    <p>{`Grades 11 and 12 follow a two-year programme designed for those who seek to enter higher education in universities worldwide.`}</p>
    <p>{`PSI holds the prestigious IB World School status, making us one of 3000 schools in 120 countries that offer the IB Diploma Programme (DP), including some of the largest and most established international schools around the world. Major universities consider the DP as the most rigorous high school programme with its combination of highly challenging academics, inquiry based research, and a commitment to service learning.`}</p>
    <h4 {...{
      "id": "assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#assessment",
        "aria-label": "assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment`}</h4>
    <p>{`Grades are based upon internally assessed assignments, externally assessed projects, and the end of programme examination papers. A student can earn up to 7 points in each of her or his 6 courses.`}</p>
    <p>{`The Theory of Knowledge and Extended Essay components provide the possibility of 3 additional points. The total possible score in the IB Diploma is 45 points.`}</p>
    <h4 {...{
      "id": "this-extraordinary-year---again",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#this-extraordinary-year---again",
        "aria-label": "this extraordinary year   again permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`This extraordinary year - again:`}</h4>
    <p>{`For the second year in a row, PSI students finishing the IB Diploma Programme did so without sitting the infamous IB exams.  Quarantine conditions in Kyiv prevented PSI from holding the exams. Although many schools in countries where the viral load was much lower were allowed to hold the exams, Ukrainian students, as well as many of those in other Eastern European countries were prohibited from sitting exams for health reasons.`}</p>
    <p>{`The IB has assured its global community that the difference between the results for the students who sat the exams and the results for those who did not sit the exams will not differ significantly.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Despite the very difficult circumstances, members of the class of 2021 established a new record of achievement by earning an average of 37 points. That is three points higher than any previous class. One student earned 44 points, one shy of a perfect score. The average score per subject sits at a remarkable 5.80 out of 7, another school record.  All 23 candidates seeking the full IB diploma earned it, the most ever in school history.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Congratulations to the class of 2021!`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      